.app-custom-accordion {
  .expand-content {
    p {
      margin-top: 0;
    }
  }

  .custom-expansion-panel {
    box-shadow: none !important; // Remove box shadow
    border: none; // Remove border if needed

    mat-expansion-panel-header {
      display: flex;
      background-color: transparent; // Ensure there's no background color
      padding: 16px 18px; // Add more padding around the header

      @media screen and (width <= 768px) {
        padding: 16px 0;
      }

      .mat-content {
        gap: 15px;
      }

      .expand-header {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: start;
        gap: 8px;
        overflow: hidden;

        .single-line-ellipsis {
          flex-shrink: 1;
          max-width: calc(
            100% - 32px
          ); /* Adjust if needed to leave space for the toggle icon */

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        h6 {
          margin: 0;
        }

        p {
          margin: 0;
        }
      }

      .toggle-icon {
        margin-left: auto; // Push the icon to the far right
        width: 20px;
        min-width: 20px;
      }
    }
  }
}
