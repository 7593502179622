@use "variables" as var;

.padded {
  padding: 0 20px;
}

.main-with-side {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  overflow-y: auto; // Allows scrolling within the container

  @media screen and (width <= 960px) {
    flex-direction: column;
    padding-bottom: 0;
  }

  .main-content {
    flex: 3;
    padding-bottom: 20px; // Adds extra spacing at the bottom
    max-width: 100%;
    overflow: hidden;

    @media screen and (width <= 960px) {
      order: 1; // Ensures this stays at the top
      padding-bottom: 0;
    }
  }

  .side-content {
    flex: 1;
    padding-top: 20px; // Adds padding at the top for better spacing
    max-width: 384px;
    overflow-y: auto; // Enables scrolling within side content

    @media screen and (width <= 960px) {
      order: 2; // Moves it below the main content
      max-width: 100%;
    }
  }
}

.scrollable-row {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  gap: 8px; /* Optional: add spacing between items */
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping of individual items */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile devices */
}

.scrollable-row::-webkit-scrollbar {
  display: none; /* Hide scrollbar for a cleaner look */
}

.flow-container {
  margin: 0 auto;
  padding: 16px;
  max-width: 680px;

  .router-outlet-wrapper {
    /* Ensure router-outlet takes full width/height */
    width: 100%;
    height: 100%;
  }

  /* Hide router-outlet by controlling visibility with CSS */
  .router-outlet-wrapper.hidden {
    visibility: hidden;
  }

  .nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    button {
      min-width: 48px;
    }

    .skip-button {
      color: var(--mat-primary-color); /* Adjust to your theme */
    }
  }

  .progress-bar {
    margin-bottom: 24px;
  }

  /* To ensure the loading container only covers the router outlet */
  .stepper-navigation {
    position: relative;
    //min-height: 800px; /* Ensure space for the loading container */
  }

  /* Define the container for loading animation */
  .loading-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    z-index: 10;
    padding: 20px;
    width: 100%;
  }

  .loading-rect {
    animation: flash 1.5s infinite ease-in-out;
    border-radius: 8px; /* Rounded corners */
    background-color: #e0e0e0; /* Lighter grey color */
    width: 100%;
    height: 50px; /* Thicker rectangles */
  }

  .loading-rect:nth-child(1) {
    width: 50%; /* Simulating a larger input like name */
  }

  .loading-rect:nth-child(2) {
    width: 75%; /* Simulating a medium input */
  }

  .loading-rect:nth-child(3) {
    width: 30%; /* Simulating a full-width input */
  }

  .loading-rect:nth-child(4) {
    width: 50%; /* Simulating a small input */
  }

  .loading-rect:nth-child(5) {
    width: 15%; /* Another large input */
  }

  @keyframes flash {
    0%,
    100% {
      opacity: 0.5;
    }

    50% {
      opacity: 1;
    }
  }
}

.tracker-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  box-shadow: none;

  .tracker-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .image-container {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      width: 80px;
      height: 80px;

      .circle-background {
        position: absolute; /* Ensure the background stays at the back */
        z-index: 1; /* Background should be behind the badge */
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .image {
        position: absolute;
        z-index: 2; /* Ensure the badge is on top of the ellipsis */
        width: 60%;
        height: 60%;
        object-fit: contain;
      }
    }

    .text-container {
      flex-grow: 1;
      margin-right: 8px;
      overflow: hidden;

      .header {
        margin: 0;
        font-weight: 600;
      }

      .subtext {
        display: -webkit-box;
        margin: 4px 0 0;
        overflow: hidden;
        color: #757575;
        line-height: 1.2;
        text-align: start;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .arrow-container {
      display: flex;
      justify-content: flex-end;
      min-width: 32px;

      .arrow-button {
        border-radius: 50%;
        background-color: #f5f5f5;
        padding: 4px;
        min-width: 32px;
        min-height: 32px;

        mat-icon {
          color: #333;
          font-size: 20px;
        }
      }
    }
  }
}

.all-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  /* Media query for smaller screens */
  @media (width <= 960px) {
    flex-direction: column; /* Stack the filters on top of the courses grid */
  }

  box-shadow: none !important;
  min-width: 300px;

  .grid-section {
    flex-grow: 1; /* Allow this section to take up as much space as possible */

    @media (width <= 960px) {
      order: 2; /* Move the grid below the filters */
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      @media (width <= 768px) {
        grid-template-columns: repeat(
          2,
          1fr
        ); /* Make the grid single column for smaller screens */
      }

      @media (width <= 600) {
        grid-template-columns: 1fr; /* Make the grid single column for smaller screens */
      }
    }

    .grid-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      @media (width <= 768px) {
        grid-template-columns: 1fr;
      }

      @media (width <= 600) {
        grid-template-columns: 1fr; /* Make the grid single column for smaller screens */
      }
    }

    .load-more-button {
      padding-bottom: 25px;
      overflow: hidden;
    }
  }

  @media (width <= 768px) {
    order: 1; /* Move the expansion panel above the grid */
  }

  .chip-list {
    display: flex;
    flex-direction: column; /* Ensure chips are in a column */
    margin-bottom: 16px;
  }

  .filters-container {
    .filter-icon-and-chips {
      display: flex;
      align-items: center;
    }
  }
}

.hero-and-actions {
  display: flex;

  .hero-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
    border-radius: 12px;
    width: 100%;

    .hero-illustration {
      img {
        max-width: 300px;
        max-height: 280px;
      }
    }

    @media (width <= 960px) {
      flex-direction: column-reverse; /* Stack the content and image vertically */
      padding: 20px; /* Reduce padding for smaller screens */
      max-height: 100%;

      .hero-illustration {
        max-width: 100%; /* Make the image full width */
      }
    }

    .hero-content {
      max-width: 70%;

      @media (width <= 960px) {
        margin-bottom: 20px; /* Add some space between the content and image */
        max-width: 100%; /* Make the content full width */
      }

      .cta-buttons {
        display: flex;
        gap: 16px;

        @media (width <= 960px) {
          justify-content: space-between;
        }

        .cta-primary {
          padding: 12px 24px;
        }

        .cta-secondary {
          padding: 12px 24px;
        }
      }

      .hero-illustration {
        max-width: 40%;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.tile-card-1 {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: none !important;
  height: 100%;

  .new-chip-container {
    position: absolute;
    top: 4px;
    left: 8px;
    z-index: 999;
  }

  .image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .image-cropper {
    position: relative;
    border-radius: 4px;
    height: 120px; /* Set a fixed height for the image cropper */
    overflow: hidden;
  }

  .tile-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire area without white space */
  }

  .tile-image.loaded {
    opacity: 1;
  }

  .skeleton-loader {
    position: absolute; /* Position it over the image */
    top: 0;
    left: 0; /* Light gray for skeleton */
    z-index: 10; /* Ensure it appears above the image */
    animation: skeleton-loading 1.5s infinite ease-in-out;
    border-radius: 12px;
    background-color: #e0e0e0;
    width: 100%;
    height: 200px; /* Match the height of the image */
  }

  @keyframes skeleton-loading {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  mat-progress-bar {
    margin-top: 8px;
  }

  mat-card-subtitle {
    margin-top: 4px;
    padding-left: 10px;

    .level-icon {
      width: 10px;
      height: 12px;
    }
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    height: 48px;

    .tile-title {
      display: -webkit-box;
      flex-grow: 1;
      margin: 0;
      max-height: 3em; /* Enough for two lines of text */
      overflow: hidden;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5em; /* Set a line-height for consistent spacing */
      text-overflow: ellipsis;
      -webkit-line-clamp: 2; /* Limits to two lines */
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    .icon-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  mat-card-content {
    flex-grow: 1;
    padding: 10px;

    .overview {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      margin-top: 10px;
      max-height: 4.5em; /* Approximately 3 lines of text */
      overflow: hidden;
      text-align: start;
      text-overflow: ellipsis;
    }
  }

  mat-card-actions {
    justify-content: center;
  }
}

.custom-progress-bar {
  border-radius: 5px;

  .caption {
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.stats-container {
  .item-img-container {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;

    .item-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .img-blur-overlay {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        rgb(255 255 255 / 0%) 0%,
        rgb(255 255 255 / 80%) 60%,
        rgb(255 255 255 / 100%) 100%
      );
      height: 50%; /* Adjust this to control the amount of blur */
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
  }

  .stats-rows {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    margin-top: 16px;
    width: 100%;

    .stat-row {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        margin: 0;
      }
    }

    @media screen and (width <= 960px) {
      flex-direction: row;
    }
  }
}

.easify-ai-response-container {
  border-radius: 4px; /* Optional: Rounded corners */
  padding: 12px; /* Add some padding for better readability */
  max-height: 300px; /* Set the maximum height for the content */
  overflow-y: auto; /* Enable vertical scrolling */
}
