@use "@angular/material" as mat;
@use "variables" as var;

// Define custom typography
$custom-typography: mat.m2-define-typography-config(
  $font-family: var.$regular-font-family,
  $headline-1:
    mat.m2-define-typography-level(
      $font-family: var.$heading-font-family,
      $font-size: 60px,
      $line-height: 112px,
      $font-weight: 300,
      $letter-spacing: -0.05em,
    ),
  $headline-2:
    mat.m2-define-typography-level(
      $font-family: var.$heading-font-family,
      $font-size: 42px,
      $line-height: 72px,
      $font-weight: 300,
      $letter-spacing: -0.05em,
    ),
  $headline-3:
    mat.m2-define-typography-level(
      $font-family: var.$heading-font-family,
      $font-size: 32px,
      $line-height: 56px,
      $font-weight: 900,
    ),
  $headline-4:
    mat.m2-define-typography-level(
      $font-family: var.$heading-font-family,
      $font-size: 34px,
      $line-height: 40px,
      $font-weight: 400,
    ),
  $headline-5:
    mat.m2-define-typography-level(
      $font-family: var.$heading-font-family,
      $font-size: 20px,
      $line-height: 30px,
      $font-weight: 500,
    ),
  $headline-6:
    mat.m2-define-typography-level(
      $font-family: var.$heading-font-family,
      $font-size: 16px,
      $line-height: 24px,
      $font-weight: 400,
    ),
  $subtitle-1:
    mat.m2-define-typography-level(
      $font-family: var.$regular-font-family,
      $font-size: 18px,
      $line-height: 24px,
      $font-weight: 100,
    ),
  $subtitle-2:
    mat.m2-define-typography-level(
      $font-family: var.$regular-font-family,
      $font-size: 14px,
      $line-height: 16px,
      $font-weight: 500,
    ),
  $body-1:
    mat.m2-define-typography-level(
      $font-family: var.$regular-font-family,
      $font-size: 14px,
      $line-height: 20px,
      $font-weight: 200,
    ),
  $body-2:
    mat.m2-define-typography-level(
      $font-family: var.$regular-font-family,
      $font-size: 12px,
      $line-height: 18px,
      $font-weight: 400,
    ),
  $button:
    mat.m2-define-typography-level(
      $font-family: var.$regular-font-family,
      $font-size: 12px,
      $line-height: 16px,
      $font-weight: 400,
    ),
  $caption:
    mat.m2-define-typography-level(
      $font-family: var.$regular-font-family,
      $font-size: 12px,
      $line-height: 16px,
      $font-weight: 100,
    ),
  $overline:
    mat.m2-define-typography-level(
      $font-family: var.$regular-font-family,
      $font-size: 10px,
      $line-height: 16px,
      $font-weight: 400,
    ),
);

// Apply typography to non-Material elements
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
button,
caption {
  font-family: var.$regular-font-family;
}

h1 {
  @include mat.m2-typography-level($custom-typography, headline-1);

  margin: 0;
}

h2 {
  @include mat.m2-typography-level($custom-typography, headline-2);

  margin: 0;
}

h3 {
  @include mat.m2-typography-level($custom-typography, headline-3);

  margin: 0;
}

h4 {
  @include mat.m2-typography-level($custom-typography, headline-4);

  margin: 0;
}

h5 {
  @include mat.m2-typography-level($custom-typography, headline-5);

  margin: 0;
}

h6 {
  @include mat.m2-typography-level($custom-typography, headline-6);

  margin: 0;
  margin-bottom: 10px;
}

summary {
  @include mat.m2-typography-level($custom-typography, subtitle-1);
}

p {
  @include mat.m2-typography-level($custom-typography, body-1);
}

button {
  @include mat.m2-typography-level($custom-typography, button);
}

caption {
  @include mat.m2-typography-level($custom-typography, caption);
}
