@use "@angular/material" as mat;
@use "variables" as var;
@use "typography" as typo;
@use "./styles/carousel.scss" as carousel;
@use "./styles/containers.scss" as containers;
@use "./styles/buttons.scss" as buttons;
@use "./styles/textbox.scss" as textbox;
@use "./styles/chips.scss" as chips;
@use "./styles/accordion.scss" as accordion;

// Include the core styles
@include mat.core();

// Dark theme
$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: var.$dark-primary-palette,
      accent: var.$dark-accent-palette,
      background: var.$dark-theme-background-palette,
    ),
    typography: typo.$custom-typography,
  )
);

// Define a light theme
$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: var.$primary-palette,
      accent: var.$accent-palette,
      background: (
        background: #fff,
        surface: #f9f9f9,
      ),
    ),
    typography: typo.$custom-typography,
  )
);

// Apply the dark theme by default
@include mat.all-component-themes($dark-theme);

// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: light) {
  @include mat.all-component-colors($light-theme);
}
