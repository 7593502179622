@use "./theme";

/* You can add global styles to this file, and also import other style files */

html,
body {
  margin: 0;
  height: 100%;
  scroll-behavior: auto;
}

fieldset {
  border: none;
}
